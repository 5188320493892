import React from "react";

import { Icon } from '@types';

const CalendarIcon: Icon = ({ fill = "white" }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M54 0H6C2.7 0 0 2.702 0 6V54C0 57.296 2.7 60 6 60H54C57.3 60 60 57.296 60 54V6C60 2.702 57.3 0 54 0ZM16.192 52.804H7V44.628H16.192V52.804ZM16.192 41.886H7V33.714H16.192V41.886ZM15.5 9.094C14.094 9.094 12.954 7.956 12.954 6.548C12.954 5.14 14.094 4 15.5 4C16.906 4 18.046 5.138 18.046 6.546C18.046 7.954 16.906 9.094 15.5 9.094ZM28.458 52.804H19.268V44.628H28.458V52.804ZM28.458 41.886H19.268V33.714H28.458V41.886ZM28.458 30.976H19.268V22.804H28.458V30.976ZM27.454 6.546C27.454 5.138 28.594 4 30 4C31.406 4 32.546 5.138 32.546 6.546C32.546 7.954 31.406 9.092 30 9.092C28.594 9.092 27.454 7.956 27.454 6.546ZM40.732 41.886H31.54V33.714H40.734V41.886H40.732ZM40.732 30.976H31.54V22.804H40.734V30.976H40.732ZM41.954 6.546C41.954 5.138 43.094 4 44.5 4C45.906 4 47.046 5.138 47.046 6.546C47.046 7.954 45.906 9.092 44.5 9.092C43.094 9.092 41.954 7.956 41.954 6.546ZM53 41.886H43.808V33.714H53V41.886ZM53 30.976H43.808V22.804H53V30.976Z" fill={fill}/>
  </svg>

);

export default CalendarIcon;
