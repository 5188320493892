import React from "react";

import { Icon } from '@types';

const EducationIcon: Icon = ({ fill = "white" }) => (
  <svg width="60" height="60" viewBox="5 5 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.0002 35.995L12.1016 30.4861V35.9059V39.6869C12.1016 43.9404 20.1177 47.3891 30.0056 47.3891C39.8929 47.3891 47.9105 43.9404 47.9105 39.6869C47.9105 39.6534 47.8995 39.6197 47.8988 39.587V30.4861L30.0002 35.995Z" fill={fill}/>
    <path d="M0 24.1525L6.39424 26.4398L6.93915 25.2728L9.28822 25.0731L9.62315 25.4216L7.60742 25.8996L7.31364 26.7691C7.31311 26.7691 2.76046 36.2869 3.42911 40.9431C3.42911 40.9431 6.27088 42.6382 9.11143 40.9431L9.86632 28.2127V27.153L14.0946 26.1991L13.796 26.9345L10.6434 27.9597L12.1015 28.4806L30.0001 33.9895L47.8986 28.4806L60 24.1526L30.0001 12.6109L0 24.1525Z" fill={fill}/>
  </svg>

);

export default EducationIcon;
